<template>
    <div class="form-check">
        <input
            class="form-check-input"
            type="checkbox"
            :checked="checked"
            :id="id"
            @change="$emit('update:checked', $event.target.checked)"
            :disabled="disabled"
        />
        <label class="form-check-label" :for="id">{{ label }}</label>
    </div>
</template>
<script>
import { v4 as uuid } from "uuid";
export default {
    props: {
        checked: Boolean,
        label: String,
        disabled: Boolean
    },
    emits: ["update:checked"],
    setup() {
        const id = uuid();
        return {
            id
        };
    }
}
</script>