<template>
    <div>
        <img
            src="@/assets/sample-jury-instructions-header-1x.webp"
            srcset="@/assets/sample-jury-instructions-header-1x.webp, @/assets/sample-jury-instructions-header-2x.webp 2x"
            alt="Sample Jury Instructions Header"
            class="responsive-image my-3"
            width="624"
            height="147"
        />
        <div>If you need a different header or have a suggested change, please contact us.</div>
    </div>
</template>
<style lang="scss" scoped>
.responsive-image {
    max-width: 100%;
    height: auto;
}
</style>