const getThemePrefix = () => {
    const primaryDomain = window.location.hostname.split(".").slice(-2).join(".").toLowerCase();
    switch (primaryDomain) {
        case "crowdsourcelawyers.com":
            return "CSL"
        case "california-business-lawyer-corporate-lawyer.com":
            return "NW";
        default: 
            return "NW";
    }
}

const setFavicon = (href) => {
    let link = document.querySelector("link[rel='icon']");
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = href;
}

export {
    getThemePrefix,
    setFavicon
}