<template>
    <div class="accordion-item">
        <div class="accordion-header">
            <button
                class="accordion-button"
                :class="{ collapsed }"
                type="button"
                @click="handleCollapseButtonClick"
            >{{ title }}</button>
        </div>
        <div class="accordion-collapse collapse" ref="collapseRef">
            <div class="accordion-body">
                <slot />
            </div>
        </div>
    </div>
</template>
<script>
import Collapse from "bootstrap/js/dist/collapse";
import { ref, onMounted, onBeforeUnmount } from 'vue'

export default {
    props: {
        title: String
    },
    setup() {
        let bsCollapse = null;
        const collapseRef = ref(null);
        const collapsed = ref(true);
        const handleShowBsCollapse = () => { collapsed.value = false; }
        const handleHideBsCollapse = () => { collapsed.value = true; }
        const handleCollapseButtonClick = () => { bsCollapse.toggle(); }
        onMounted(() => {
            collapseRef.value.addEventListener("show.bs.collapse", handleShowBsCollapse);
            collapseRef.value.addEventListener("hide.bs.collapse", handleHideBsCollapse);
            bsCollapse = new Collapse(collapseRef.value, { toggle: false });
        });
        onBeforeUnmount(() => {
            collapseRef.value.removeEventListener("show.bs.collapse", handleShowBsCollapse);
            collapseRef.value.removeEventListener("hide.bs.collapse", handleHideBsCollapse);
            bsCollapse = null;
        });
        return {
            collapseRef,
            collapsed,
            handleCollapseButtonClick
        }
    }
}
</script>