<template>
    <div class="d-flex">
        <label :for="id" class="align-self-center pe-2">{{ label }}</label>
        <div class="flex-fill align-self-center">
            <input
                type="text"
                class="form-control"
                :id="id"
                :value="value"
                @change="$emit('update:value', $event.target.value)"
            />
        </div>
    </div>
</template>
<script>
import { v4 as uuid } from "uuid";

export default {
    props: {
        label: String,
        value: String
    },
    emits: ["update:value"],
    setup() {
        const id = uuid();
        return { id }
    }

}
</script>