<template>
    <div>
        <a href="https://california-business-lawyer-corporate-lawyer.com/" target="_blank" rel="noopener">
            <img
                src="@/assets/nakase-wade-logo-1x.webp"
                srcset="@/assets/nakase-wade-logo-1x.webp, @/assets/nakase-wade-logo-2x.webp 2x"
                alt="Nakase Wade Logo"
                width="302"
                height="90"
            />
        </a>
    </div>
</template>