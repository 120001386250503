<template>
    <h1 class="heading">CACI Jury Instructions Fillable Forms</h1>
    <h2
        class="lead"
    >This free automated California CACI jury instruction will generate a CACI jury instructions fillable forms in MS Word format. When you click “Download”, check your browser’s download folder for CACI Jury instructions word format. The download is a CACI Jury Instructions fillable forms.</h2>
</template>
<style lang="scss" scoped>
@import "@/scss/_includes.scss";

.heading {
    @include font-size($h1-font-size);
    font-weight: $font-weight-bolder;
    margin-top: map-get($spacers, 5);
    margin-bottom: map-get($spacers, 5);
    text-align: center;
}
.lead {
    @include font-size($h5-font-size);
    font-weight: $font-weight-lighter;
    margin-top: map-get($spacers, 5);
    margin-bottom: map-get($spacers, 5);
}
</style>