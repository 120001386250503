<template>
    <button
        type="button"
        class="btn btn-primary btn-lg d-flex justify-content-center align-items-center w-100 mt-5 mb-5"
        :disabled="disabled"
        @click="$emit('click', $event)"
    >
        <span v-show="busy" class="spinner-border spinner-border-sm me-1"></span>
        <span>Download MS Word Doc</span>
    </button>
</template>
<script>
export default {
    props: {
        busy: Boolean,
        disabled: Boolean,
    },
    emits: ["click"],
}
</script>