<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <component :is="headerComponentName" />
        <HeroSection />
        <hr />
        <Form
          :caciData="caciData"
          :busy="busy"
          :caciWordDocGenerationOptions="caciWordDocGenerationOptions"
          @update:caciWordDocGenerationOptions="handleUpdateCaciWordDocGenerationOptions"
          @downloadButtonClick="handleDownloadButtonClick"
        />
      </div>
    </div>
  </div>
</template>
<script>
import NwHeader from "./NwHeader.vue";
import CslHeader from "./CslHeader.vue";
import HeroSection from "./HeroSection.vue";
import Form from "./Form.vue";
import caciData from "@/data/caci-data.json";
import { ref } from "vue";
import { getThemePrefix, setFavicon } from "@/utils.js";
import _camelCase from "lodash/camelCase"; 

export default {
  components: {
    NwHeader,
    CslHeader,
    HeroSection,
    Form
  },
  setup() {
    const busy = ref(false);
    const caciWordDocGenerationOptions = ref({
      juryInstructionsHeader: true,
      directionsAndSources: true,
      nameOfPlantiff: "",
      nameOfDefendant: "",
      caciIds: []
    });
    const themePrefix = getThemePrefix();
    setFavicon(`/${themePrefix.toLowerCase()}-favicon.ico`);
    const headerComponentName = `${_camelCase(themePrefix)}Header`;
    const handleUpdateCaciWordDocGenerationOptions = (o) => {
      caciWordDocGenerationOptions.value = o;
    }
    const handleDownloadButtonClick = async () => {
      try {
        busy.value = true;
        const response = await fetch(`${process.env.VUE_APP_API_PREFIX}/CaciWordDoc`, {
          method: "post",
          body: JSON.stringify(caciWordDocGenerationOptions.value),
          headers: {
            "Content-Type": "application/json; charset=utf-8"
          }
        });
        const blob = await response.blob();
        const contentDisposition = response.headers.get("Content-Disposition");
        const fileName = /filename="(.*)";/.exec(contentDisposition)[1];
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
          URL.revokeObjectURL(a.href);
          document.body.removeChild(a);
        }, 0);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        alert("Error downloading file. Please try again.");
      } finally {
        busy.value = false;
      }
    }
    return {
      caciData,
      busy,
      caciWordDocGenerationOptions,
      headerComponentName,
      handleUpdateCaciWordDocGenerationOptions,
      handleDownloadButtonClick
    };
  }
}
</script>
