<template>
    <div class="form-check form-check-inline">
        <input
            class="form-check-input"
            type="radio"
            :name="name"
            :id="ids[0]"
            :checked="selected === true"
            @change="$emit('update:selected', !selected)"
        />
        <label class="form-check-label" :for="ids[0]">Yes</label>
    </div>
    <div class="form-check form-check-inline">
        <input
            class="form-check-input"
            type="radio"
            :name="name"
            :id="ids[1]"
            :checked="selected === false"
            @change="$emit('update:selected', !selected)"
        />
        <label class="form-check-label" :for="ids[1]">No</label>
    </div>
</template>
<script>
import { v4 as uuid } from "uuid";
export default {
    props: {
        selected: Boolean
    },
    emits: ["update:selected"],
    setup() {
        const name = uuid();
        const ids = [uuid(), uuid()];
        return {
            name,
            ids,
        };
    },

}
</script>