<template>
    <div class="accordion">
        <AccordionItem v-for="(items, topic) in topics" :title="topic" :key="topic">
            <CheckboxItem
                v-for="item in items"
                :key="item.id"
                :label="`${item.id} - ${item.title}`"
                :checked="isSelected(item.id)"
                :disabled="item.hasContent === false"
                @update:checked="handleSelectionChanged(item.id, $event)"
                class="mb-2"
            />
        </AccordionItem>
    </div>
</template>
<script>
import { computed } from "vue";
import AccordionItem from "./AccordionItem.vue";
import CheckboxItem from "./CheckboxItem.vue";
import _groupBy from "lodash/groupBy";

export default {
    components: {
        AccordionItem,
        CheckboxItem,
    },
    props: {
        caciData: Array,
        selectedIds: Array,
    },
    emits: ["update:selectedIds"],
    setup(props, { emit }) {
        const handleSelectionChanged = (id, selected) => {
            let temp = selected ? [...props.selectedIds, id] : props.selectedIds.filter(value => value !== id);
            emit("update:selectedIds", temp);
        };
        const isSelected = computed(() => {
            return (id) => props.selectedIds.includes(id);
        });
        const topics = computed(() => {
            return _groupBy(props.caciData, o => o.topic);
        });
        return {
            handleSelectionChanged,
            isSelected,
            topics
        };
    },
}
</script>