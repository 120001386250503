<template>
    <div class="title">{{ title }}</div>
    <div class="px-md-4">
        <slot />
    </div>
</template>
<style lang="scss" scoped>
@import "@/scss/_includes.scss";

.title {
    @include font-size($h5-font-size);
    margin-top: map-get($spacers, 5);
    margin-bottom: map-get($spacers, 3);
}
</style>
<script>
export default {
    props: {
        title: String
    }
}
</script>