<template>
    <form>
        <fieldset :disabled="busy">
            <FormQuestion title="1. Do you want a header for each jury instruction?">
                <YesNoRadioGroup
                    :selected="caciWordDocGenerationOptions.juryInstructionsHeader"
                    @update:selected="juryInstructionsHeader => handleUpdateCaciWordDocGenerationOptions({ juryInstructionsHeader })"
                />
                <SampleJuryInstructionsHeader />
            </FormQuestion>
            <FormQuestion
                title="2. Do you want to include “Directions for Use” and “Sources and Authority”?"
            >
                <div class="my-3">
                    Note: Generally, two jury instructions are given to the court: 1) A copy to the judge with
                    supporting “Direction for Use” and “Sources and Authority”; 2) A copy for the jury without
                    “Direction for Use” and “Sources and Authority.”
                </div>
                <YesNoRadioGroup
                    :selected="caciWordDocGenerationOptions.directionsAndSources"
                    @update:selected="directionsAndSources => handleUpdateCaciWordDocGenerationOptions({ directionsAndSources })"
                />
            </FormQuestion>
            <FormQuestion title="3. Replace [Name of Plaintiff] with true name.">
                <TextInput
                    label="Name of Plantiff:"
                    :value="caciWordDocGenerationOptions.nameOfPlantiff"
                    @update:value="nameOfPlantiff => handleUpdateCaciWordDocGenerationOptions({ nameOfPlantiff })"
                />
            </FormQuestion>
            <FormQuestion title="4. Replace [Name of Defendant] with true name.">
                <TextInput
                    label="Name of Defendant:"
                    :value="caciWordDocGenerationOptions.nameOfDefendant"
                    @update:value="nameOfDefendant => handleUpdateCaciWordDocGenerationOptions({ nameOfDefendant })"
                />
            </FormQuestion>
            <FormQuestion
                title="5. Please select the jury instructions. Then, click “Download MS Word Doc.”"
            >
                <JuryInstructionsPicker
                    :caciData="caciData"
                    :selectedIds="caciWordDocGenerationOptions.caciIds"
                    @update:selectedIds="caciIds => handleUpdateCaciWordDocGenerationOptions({ caciIds })"
                />
            </FormQuestion>
            <DownloadButton
                :busy="busy"
                :disabled="downloadButtonDisabled"
                @click="e => $emit('downloadButtonClick', e)"
            />
        </fieldset>
    </form>
</template>
<script>
import FormQuestion from "@/components/FormQuestion.vue";
import SampleJuryInstructionsHeader from "@/components/SampleJuryInstructionsHeader.vue";
import YesNoRadioGroup from "@/components/YesNoRadioGroup.vue";
import JuryInstructionsPicker from "@/components/JuryInstructionsPicker.vue";
import TextInput from "@/components/TextInput.vue";
import DownloadButton from "@/components/DownloadButton.vue";
import { computed } from "vue";

export default {
    components: {
        FormQuestion,
        SampleJuryInstructionsHeader,
        YesNoRadioGroup,
        JuryInstructionsPicker,
        TextInput,
        DownloadButton
    },
    props: {
        caciData: Array,
        busy: Boolean,
        caciWordDocGenerationOptions: Object,
    },
    emits: [
        "update:caciWordDocGenerationOptions",
        "downloadButtonClick"
    ],
    setup(props, { emit }) {
        const downloadButtonDisabled = computed(() => {
            return props.caciWordDocGenerationOptions.caciIds.length === 0;
        });
        const handleUpdateCaciWordDocGenerationOptions = (o) => {
            const temp = Object.assign({}, props.caciWordDocGenerationOptions, o);
            emit("update:caciWordDocGenerationOptions", temp);
        };
        return {
            downloadButtonDisabled,
            handleUpdateCaciWordDocGenerationOptions
        }
    }
}
</script>
